<template>
    <div>
        <Header />
        <router-view></router-view>
        <Footer />
    </div>
</template>
<script>
import Header from '@/views/layouts/HeaderComponent.vue';
export default {
    name: 'App',
    components: {
        Header
    },
    data() {
        return {

        };
    },
};
</script>