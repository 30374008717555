<template>
	<!-- Navigation Bar-->
	<header id="topnav">
		<div class="topbar-main navbar p-0">
			<div class="container-fluid">
				<!-- Logo container-->
				<div class="topbar-left">
					<!-- Text Logo -->
					<a href="index.html" class="logo"> BANK DATA GIZKIA </a>
				</div>
				<!-- End Logo container-->

				<div class="menu-extras">
					<ul class="nav navbar-right float-right">
						<li class="dropdown navbar-c-items">
							<a href="" class="dropdown-toggle waves-effect waves-light profile" data-toggle="dropdown"
								aria-expanded="true"><img :src="avatar" alt="user-img" class="rounded-circle" />
							</a>
							<ul class="dropdown-menu dropdown-menu-right user-list notify-list">
								<li>
									<a href="javascript:void(0)" class="dropdown-item"><i
											class="mdi mdi-power-standby m-r-5"></i> Logout</a>
								</li>
							</ul>
						</li>
					</ul>
					<div class="menu-item">
						<!-- Mobile menu toggle-->
						<a class="navbar-toggle" id="open-mega">
							<div class="lines">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</a>
						<!-- End mobile menu toggle-->
					</div>
				</div>
				<!-- end menu-extras -->
			</div>
			<!-- end container-fluid -->
		</div>
		<!-- end topbar-main -->

		<div class="navbar-custom">
			<div class="container-fluid">
				<div id="navigation">
					<!-- Navigation Menu-->
					<ul class="navigation-menu">
						<li>
							<router-link :to="{ name: 'Home' }"><i class="mdi mdi-home"></i>Home</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'dataDasar' }"><i class="mdi mdi-database"></i>Data Dasar
							</router-link>
						</li>
						<li class="has-submenu last-elements">
							<router-link :to="{ name: 'bumil' }"><i class="mdi mdi-human-pregnant"></i>Bumil
							</router-link>
							<ul class="submenu">
								<li>
									<router-link :to="{ name: 'bumil' }">Ibu Hamil</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'bumilRisti' }">Ibu Hamil Risti</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'bumilMelahirkan' }">Ibu Melahirkan</router-link>
								</li>
							</ul>
						</li>
						<li>
							<router-link :to="{ name: 'balita' }"><i class="mdi mdi-face"></i>Data Balita
							</router-link>
						</li>
						<li class="has-submenu last-elements">
							<router-link :to="{ name: 'rekap' }"><i class="mdi mdi-clipboard-list"></i> Rekapitulasi
							</router-link>
							<ul class="submenu">
								<li>
									<router-link :to="{ name: 'rekap_bumil' }">Rekap Bumil</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'rekap_balita' }">Rekap Balita</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'rekap_imunisasi' }">Rekap Imunisasi</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'rekap_gizi' }">Rekap Gizi</router-link>
								</li>
							</ul>
						</li>
						<li class="has-submenu last-elements">
							<router-link :to="{ name: 'grafik' }"><i class="mdi mdi-signal-cellular-3"></i> Grafik
								Data
							</router-link>
							<ul class="submenu">
								<li>
									<router-link :to="{ name: 'grafik_bumil' }">Data Bumil</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'grafik_balita' }">Data Balita</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'grafik_imunisasi' }">Data Imunisasi</router-link>
								</li>
							</ul>
						</li>
						<li>
							<router-link :to="{ name: 'imunisasi' }"><i class="mdi mdi-pill"></i>Data Imunisasi
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'petaTemuan' }"><i class="mdi mdi-map-outline"></i>Peta Temuan
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'petaBumil' }"><i class="mdi mdi-search-web"></i>Peta Bumil
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'petaBayi' }"><i class="mdi mdi-map-search-outline"></i>Peta Bayi
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'periode' }"><i class="mdi mdi-calendar-edit"></i>Ganti Periode
							</router-link>
						</li>
					</ul>
					<!-- End navigation menu -->
				</div>
				<!-- end #navigation -->
			</div>
			<!-- end container-fluid -->
		</div>
		<!-- end navbar-custom -->
	</header>
	<!-- End Navigation Bar-->
</template>

<script>
import Avatar from "../../assets/images/profile.png";
export default {
	name: "HeaderComponent",
	data() {
		return {
			avatar: Avatar
		};
	},
};
</script>

<style lang="css" scoped>

</style>
